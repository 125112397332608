div.traffic-situation-kpi div.title{
    width: 105px;
    line-height: 30px;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid #8C8C8C;
    color: #BFBFBF;
    text-align: center;
}

div.traffic-situation-kpi div.value{
    width: 105px;
    line-height: 32px;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 600;
    color: #85AFD4;
    text-align: center;
}

div.traffic-situation-kpi div.title-large{
    width: 120px;
    line-height: 32px;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
    border-bottom: 1px solid #8C8C8C;
    color: #BFBFBF;
    text-align: center;
}

div.traffic-situation-kpi div.value-large{
    width: 120px;
    line-height: 38px;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 600;
    color: #85AFD4;
    text-align: center;
}
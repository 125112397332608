.app-demoscreen {
    display: grid;
    width: 100vw;
    height: 100vh;
    text-align: center;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "lefttop center righttop" "leftbottom center rightbottom";
}

.kpi-demoscreen {
    display: grid;
    width: 100vw;
    height: 100vh;
    text-align: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "lefttop righttop" "leftbottom rightbottom";
}

.lefttop {
    grid-area: lefttop;
}

.righttop {
    grid-area: righttop;
}

.center {
    grid-area: center;
}

.leftbottom {
    grid-area: leftbottom;
}

.rightbottom {
    grid-area: rightbottom;
}

.app-demoscreen iframe {
    height: 100%;
    width: 100%;
}

.kpi-demoscreen iframe {
    height: 100%;
    width: 100%;
}
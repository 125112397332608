
.top5-table {
    background-color: #0f3764;
    height: 100vh;
    font-family: BloggerSans;
}

.top5-table .title {
    padding-top: 3.5%;
    color: white;
    font-weight: 600;
    text-align: center;
    font-size: 5.8vw;
}

.top5-table .header {
    font-family: BloggerSans;
    font-weight: 100;
    line-height: 1;
}

.top5-table .header > div {
    display: inline-block;
    font-weight: bolder;
    font-size: small;
    font-size: 5.7vh;
    color: white;
    padding-bottom: .7vh;
}

.top5-table .indicator.table > div:first-child {
    border-bottom: white 2px solid;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1.6vh;
    padding-right: 1.6vh;
}

.top5-table .header > div:nth-child(1) {
    width: 50%;
}

.top5-table .header > div:nth-child(2) {
    width: 30%;
    text-align: right;
}

.top5-table .header > div:nth-child(3) {
    width: 20%;
    text-align: right;
}

.top5-table .indicator.table {
    padding: 3.5% 5.5%;
    width: 89%;
    max-width: none;
    font-size: 5.7vh;
    padding-top: 2.2%;
}

.row-delays {
    font-family: BloggerSans;
    position: relative;
    padding-bottom: .7vh;
    text-transform: uppercase;
}

.row-delays > div {
    display: inline-block;
}

.row-delays div.id {
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.row-delays div.delay {
    text-align: right;
    width: 30%;
}

.row-delays div.value {
    margin-left: 8%;
    width: 12%;
    float: none;
    border-radius: 90px;
    position: relative;
    top: -.5vh;
}

.row-delays div.value span {
    position: relative;
    top: .5vh;
}

.tracking-in-expand {
    animation: tracking-in-expand 0.75s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}
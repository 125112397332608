.canary {
    position: absolute;
    right: 12px;
    bottom: 12px;
    height: 10px;
    width: 10px;
    border-radius: 5px;
}

.canary.green {
    background-color: rgba(0, 255, 0, 0.76);
    box-shadow: 2px 4px 8px 0 rgba(109, 245, 63, 0.35), 0 6px 20px 0 rgba(178, 220, 11, 0.35);
}

.canary.red {
    background-color: rgba(255, 0, 0, 0.76);
    box-shadow: 2px 4px 8px 0 rgba(245, 109, 63, 0.35), 0 6px 20px 0 rgba(220, 178, 11, 0.35);
}

.canary.dead {
    display: none
}

.indicator-kpi {
    width: 100%;
    height: 100%;
    text-align: center;
    flex-direction: column;
    position: relative;
}

.indicator-kpi > div {
    display: inline-block;
    color: #307ec7;
    text-align: center;
}

.indicator-kpi .icon {
    width: 86%;
    height: 86%;
    background: #003366;
    border-radius: 900px;
    text-align:center;
}

.indicator-kpi .icon img {
    width: 70%;
    height: 70%;
}

.indicator-kpi .title {
    width: 80%;
    height: 20%;
    top: 54%;
    left: 10%;
    font-size: 5.7vw;
    font-weight: 600;
    color: #3399cc;
    position:absolute;
}

.indicator-kpi .value {
    width: 80%;
    height: 30%;
    top: 60%;
    left: 10%;
    font-size: 10vw;
    color: white;
}

.icon .planned {
    transform: rotate(-90deg);
}

.icon .airborne {
    transform: rotate(-30deg) translate(5%, 5%);
}

.icon .landed {
    transform: rotate(30deg) translate(5%, 5%);
}

.indicator {
    color: #6d6e70;
    padding: 5px;
    font-size: larger;
    max-width: 300px;
    position: relative;
}

.indicator > div:first-child {
    padding-left: 15px;
    text-align: left;
}

.indicator > div:nth-child(2) {
    display: flex;
}

.indicator .ind-value {
    display: flex;
    align-items: center;
}

.indicators-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row: auto;
    /*grid-column-gap: 20px;*/
    grid-row-gap: 20px;
    position: relative;
}

.indicators-block > div.indicator {
    max-width: 150px;
}

.indicator.line {
    max-width: 300px;
    text-align: left;
    padding-left: 19px;
}

.indicator.line > span {
    display: inline-block;
}

.indicator.line > span:first-child {
    min-width: 115px;
}

.indicator.line > span:nth-child(2) {
    color: white;
    font-size: larger;
}

.indicator.table {
    font-size: large;
}

.indicator.table > div:first-child {
    border-bottom: #fff 1px solid;
    padding: 5px;
}

.indicator.table > div:nth-child(2) {
    flex-direction: column;
    color: white;
    width: 100%;
}

.row-delays {
    float: left;
    border-bottom: #fff 1px solid;
    padding: 1.6vh;
}

.row-delays div.id {
    width: 15%;
    float: left;
}

.row-delays div.type {
    width: 55%;
    text-align: left;
    float: left;
    word-break: break-all;
}

.row-delays div.value {
    width: 24%;
    text-align: center;
    float: left;
    margin-left: 5%;
    border-radius: 8px;
    line-height: 1.3;
    transition: background-color 600ms linear, color 600ms linear;
}

.row-delays div.value.critical {
    background-color: red;
}

.row-delays div.value.alert {
    background-color: rgba(245, 102, 00, 1);
}

.row-delays div.value.warn {
    background-color: rgba(255, 255, 00, 1);
    color: black;
}

.row-delays div.value.regulated {
    background-color: white;
    color: black;
}

div.slide-show-indicator{
    width: 622px;
    position: absolute;
    right: 54px;
    top: 599px;
    padding: 0px 26px 26px 26px;
    background-color: rgba(0,21,40,0.66);
}

div.slide-show-indicator div.slider {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-left:auto;
    margin-right:auto;

    width: 36px;
}

div.slide-show-indicator div.slider span {
    border: 1px solid #FDFDFD;
    width:12px;
    height:12px;
    border-radius: 50%;
    display: inline-block;
}

div.slide-show-indicator div.slider span.selected {
    background-color:  #FDFDFD;
}
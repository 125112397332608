.logo {
    position: absolute;
    top: 0px;
    left: 50px;
}

.logo img {
    width: 91px;
    height: 97px;
}

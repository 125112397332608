@font-face {
    font-family: BloggerSans;
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/Blogger_Sans.otf);
}

@font-face {
    font-family: BloggerSans;
    font-style: normal;
    font-weight: 300;
    src: url(./fonts/Blogger_Sans-Light.otf);
}

@font-face {
    font-family: BloggerSans;
    font-style: normal;
    font-weight: 600;
    src: url(./fonts/Blogger_Sans-Bold.otf);
}

@font-face {
    font-family: BloggerSans;
    font-style: normal;
    font-weight: 500;
    src: url(./fonts/Blogger_Sans-Medium.otf);
}

/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/roboto/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'),
         url('./fonts/roboto/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/roboto/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/roboto/roboto-v20-latin-regular.woff') format('woff'), /* Pretty Modern Browsers */
         url('./fonts/roboto/roboto-v20-latin-regular.ttf')  format('truetype'), /* Safari, Android, iOS */
         url('./fonts/roboto/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }


body {
    margin: 0;
    padding: 0;
    font-family: BloggerSans, Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

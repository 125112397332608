.Dropzone {
    width:330px;
    height:150px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

.DropzoneReject {
    width:330px;
    height:150px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #ff1744;
    color: #ffffff;
    opacity: 0.75;
    outline: none;
    transition: border .24s ease-in-out;
}

.DropzoneAccept {
    width:330px;
    height:150px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #00e676;
    color: #ffffff;
    opacity: 0.75;
    outline: none;
    transition: border .24s ease-in-out;
}

.successMessage {
    font-size: 20px;
    color: #00e676;
    text-align: center;
}

.failureMessage {
    font-size: 20px;
    color: #ff1744;
    text-align: center;
}

.downloadLink {
    font-size: 18px;
    text-align: center;
    padding-top: 25px;
}

section.traffic-situation{
    background-color: rgba(0,21,40,0.66);
    width: 622px;
    padding: 22px 26px 0px 26px;
    position: absolute;
    right: 54px;
    top: 54px;
}

section.traffic-situation h5{
    font-family: "Arial Rounded MT Bold", "Helvetica Rounded", Arial, sans-serif; 
    font-size: 28px;
    line-height: 32px;
    color: #FDFDFD;
    text-align: center;
    padding-top: 0px;
    margin-top: 0px;
    margin-bottom: 30px;
}
section.traffic-situation .container{
    display: flex;
    justify-content: center;
}

section.traffic-situation .container .big-icons{
    display: inline-flex;
    gap: 20px;
    margin-left: 26px;
}

section.traffic-situation .container .small-icons{
    display: inline-flex;
    gap: 10px;
    margin-top: 11px;
    padding-left: 26px;
    margin-right: 26px;
}
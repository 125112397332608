@font-face {
    font-family: MyriadPro-Light;
    font-style: normal;
    src: url(../../../styles/fonts/MyriadPro-Light.ttf);
}

.map {
    min-height: 100vh;
    background-color: #0e171d;
}

.upLeft {
    position: absolute;
    vertical-align: top;
    horiz-align: center;
    text-align: left;
    top: 25px;
    left: 20px;
}

.upLeft2 {
    position: absolute;
    vertical-align: top;
    text-align: left;
    top: 30px;
    left: 460px;
}

.upLeft3 {
    position: absolute;
    vertical-align: top;
    text-align: left;
    font-weight: bold;
    top: 150px;
    left: 30px;
}

.upLeft4 {
    position: absolute;
    vertical-align: top;
    text-align: left;
    top: 27px;
    left: 230px;
}

.upcenter {
    position: absolute;
    top: 15px;
    left: 50%;
    margin-right: -50%;
    z-index: 90;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#livetimelabel {
    font: 43px MyriadPro-Light;
    font-weight: bold;
    z-index: 70;
    background-color: transparent;
    pointer-events: none;
    color: #ffffff;
}

#timelabel {
    font: 24px MyriadPro-Light;
    font-weight: normal;
    z-index: 70;
    background-color: transparent;
    pointer-events: none;
    color: #ffffff;
}

#predictionlabel {
    font: 27px MyriadPro-Light;
    font-weight: bold;
    z-index: 70;
    background-color: transparent;
    pointer-events: none;
    color: #ffffff;
}

#livetrafficlabel {
    font: 45px MyriadPro-Light;
    font-weight: bold;
    z-index: 70;
    left: 30px;
    background-color: transparent;
    pointer-events: none;
    color: #ffffff;
}

.luciad .lcdBalloon {
    z-index: 400;
}

.luciad .lcdBalloon .lcdFrame {
    position: relative;
    background-color: rgb(13, 20, 28);
    opacity: 0.6;
}

.luciad .lcdBalloon .hr {
    width: 100%;
    margin-left: -10%;
}

.luciad .lcdBalloon .lcdHeader {
    width: 100%;
    height: 20px;
    margin: 0;
    border: 0;
    padding: 0;
}

.luciad .lcdBalloon .lcdContent {
    width: 100%;
    padding: 0 50px 0px 50px;
    overflow: auto;
    font: 25px MyriadPro-Light;
}

.luciad .lcdBalloon .lcdContent .header {
    margin: 0;
    font-size: 200%;
    font-weight: bold;
}

.luciad .lcdBalloon .lcdContent .properties {
    display: block;
    margin: 0;
    font-size: 100%;
    font-weight: bold;
}

.luciad .lcdBalloon .lcdContent .values {
    color: #fff;
    font-size: 120%;
    font-weight: normal;
    text-align: center;
}

.flip-scale-up-hor {
    animation: flip-scale-up-hor 1s alternate-reverse both;
    animation-iteration-count: 1;
}

.aerodromelabel {
    text-align: center;
    font: 15px MyriadPro-Light;
    font-weight: 600;
    background-color: rgba(0, 26, 52, 0.5);
    border-radius: 5px;
}

.locationLabel {
    text-align: center;
    font: 15px MyriadPro-Light;
    font-weight: 600;
    background-color: rgba(0, 26, 52, 0.5);
    border-radius: 5px;
}

@keyframes flip-scale-up-hor {
    0% {
        transform: scale(1) rotateX(0);
    }
    50% {
        transform: scale(1) rotateX(-90deg);
    }
    100% {
        transform: scale(1) rotateX(-180deg);
    }
}

@keyframes an {
    from {
        opacity: 0;
        transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
    }
    to {
        opacity: 1;
        transform: perspective(500px) translate3d(0, 0, 0);
    }
}

.loadingpanel {
    overflow-y: auto;
    z-index: 90;
    background-color: rgba(29, 34, 39, 0);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    font-weight: bold;
}

.loadingpanelpredict {
    overflow-y: auto;
    z-index: 90;
    background-color: rgba(29, 34, 39, 0.5);
    position: absolute;
    border: 1px solid rgb(13, 20, 28);
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.infopanel {
    text-align: center;
    padding: 20px;
    font: 25px MyriadPro-Light;
    overflow-y: auto;
    color: #ffffff;
    opacity: 0.7;
}


.reroutinglabelwindow {
    overflow-y: auto;
    z-index: 400;
    /*background-color: rgba(51, 153, 204, 0.7);*/
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 15px;
    position: absolute;
    top: 35%;
    left: 25%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.reroutinglabelpanel {
    text-align: left;
    padding: 20px;
    font: 30px MyriadPro-Light;
    overflow-y: auto;
    color: #ffffff;
    opacity: 0.7;
}
@font-face {
    font-family: BloggerSans;
    font-style: normal;
    font-weight: 400;
    src: url(/static/media/Blogger_Sans.15bf494d.otf);
}

@font-face {
    font-family: BloggerSans;
    font-style: normal;
    font-weight: 300;
    src: url(/static/media/Blogger_Sans-Light.b8bf30a6.otf);
}

@font-face {
    font-family: BloggerSans;
    font-style: normal;
    font-weight: 600;
    src: url(/static/media/Blogger_Sans-Bold.ea352dba.otf);
}

@font-face {
    font-family: BloggerSans;
    font-style: normal;
    font-weight: 500;
    src: url(/static/media/Blogger_Sans-Medium.2893ff69.otf);
}

/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(/static/media/roboto-v20-latin-regular.4be1a572.eot); /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'),
         url(/static/media/roboto-v20-latin-regular.4be1a572.eot?#iefix) format('embedded-opentype'), 
         url(/static/media/roboto-v20-latin-regular.479970ff.woff2) format('woff2'), 
         url(/static/media/roboto-v20-latin-regular.60fa3c06.woff) format('woff'), 
         url(/static/media/roboto-v20-latin-regular.329ae1c3.ttf)  format('truetype'), 
         url(/static/media/roboto-v20-latin-regular.8681f434.svg#Roboto) format('svg'); /* Legacy iOS */
  }


body {
    margin: 0;
    padding: 0;
    font-family: BloggerSans, Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: MyriadPro-Light;
    font-style: normal;
    src: url(/static/media/MyriadPro-Light.f4bf8ccd.ttf);
}

.map {
    min-height: 100vh;
    background-color: #0e171d;
}

.upLeft {
    position: absolute;
    vertical-align: top;
    horiz-align: center;
    text-align: left;
    top: 25px;
    left: 20px;
}

.upLeft2 {
    position: absolute;
    vertical-align: top;
    text-align: left;
    top: 30px;
    left: 460px;
}

.upLeft3 {
    position: absolute;
    vertical-align: top;
    text-align: left;
    font-weight: bold;
    top: 150px;
    left: 30px;
}

.upLeft4 {
    position: absolute;
    vertical-align: top;
    text-align: left;
    top: 27px;
    left: 230px;
}

.upcenter {
    position: absolute;
    top: 15px;
    left: 50%;
    margin-right: -50%;
    z-index: 90;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#livetimelabel {
    font: 43px MyriadPro-Light;
    font-weight: bold;
    z-index: 70;
    background-color: transparent;
    pointer-events: none;
    color: #ffffff;
}

#timelabel {
    font: 24px MyriadPro-Light;
    font-weight: normal;
    z-index: 70;
    background-color: transparent;
    pointer-events: none;
    color: #ffffff;
}

#predictionlabel {
    font: 27px MyriadPro-Light;
    font-weight: bold;
    z-index: 70;
    background-color: transparent;
    pointer-events: none;
    color: #ffffff;
}

#livetrafficlabel {
    font: 45px MyriadPro-Light;
    font-weight: bold;
    z-index: 70;
    left: 30px;
    background-color: transparent;
    pointer-events: none;
    color: #ffffff;
}

.luciad .lcdBalloon {
    z-index: 400;
}

.luciad .lcdBalloon .lcdFrame {
    position: relative;
    background-color: rgb(13, 20, 28);
    opacity: 0.6;
}

.luciad .lcdBalloon .hr {
    width: 100%;
    margin-left: -10%;
}

.luciad .lcdBalloon .lcdHeader {
    width: 100%;
    height: 20px;
    margin: 0;
    border: 0;
    padding: 0;
}

.luciad .lcdBalloon .lcdContent {
    width: 100%;
    padding: 0 50px 0px 50px;
    overflow: auto;
    font: 25px MyriadPro-Light;
}

.luciad .lcdBalloon .lcdContent .header {
    margin: 0;
    font-size: 200%;
    font-weight: bold;
}

.luciad .lcdBalloon .lcdContent .properties {
    display: block;
    margin: 0;
    font-size: 100%;
    font-weight: bold;
}

.luciad .lcdBalloon .lcdContent .values {
    color: #fff;
    font-size: 120%;
    font-weight: normal;
    text-align: center;
}

.flip-scale-up-hor {
    animation: flip-scale-up-hor 1s alternate-reverse both;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}

.aerodromelabel {
    text-align: center;
    font: 15px MyriadPro-Light;
    font-weight: 600;
    background-color: rgba(0, 26, 52, 0.5);
    border-radius: 5px;
}

.locationLabel {
    text-align: center;
    font: 15px MyriadPro-Light;
    font-weight: 600;
    background-color: rgba(0, 26, 52, 0.5);
    border-radius: 5px;
}

@-webkit-keyframes flip-scale-up-hor {
    0% {
        -webkit-transform: scale(1) rotateX(0);
                transform: scale(1) rotateX(0);
    }
    50% {
        -webkit-transform: scale(1) rotateX(-90deg);
                transform: scale(1) rotateX(-90deg);
    }
    100% {
        -webkit-transform: scale(1) rotateX(-180deg);
                transform: scale(1) rotateX(-180deg);
    }
}

@keyframes flip-scale-up-hor {
    0% {
        -webkit-transform: scale(1) rotateX(0);
                transform: scale(1) rotateX(0);
    }
    50% {
        -webkit-transform: scale(1) rotateX(-90deg);
                transform: scale(1) rotateX(-90deg);
    }
    100% {
        -webkit-transform: scale(1) rotateX(-180deg);
                transform: scale(1) rotateX(-180deg);
    }
}

@-webkit-keyframes an {
    from {
        opacity: 0;
        -webkit-transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
                transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
    }
    to {
        opacity: 1;
        -webkit-transform: perspective(500px) translate3d(0, 0, 0);
                transform: perspective(500px) translate3d(0, 0, 0);
    }
}

@keyframes an {
    from {
        opacity: 0;
        -webkit-transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
                transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
    }
    to {
        opacity: 1;
        -webkit-transform: perspective(500px) translate3d(0, 0, 0);
                transform: perspective(500px) translate3d(0, 0, 0);
    }
}

.loadingpanel {
    overflow-y: auto;
    z-index: 90;
    background-color: rgba(29, 34, 39, 0);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-transform: uppercase;
    font-weight: bold;
}

.loadingpanelpredict {
    overflow-y: auto;
    z-index: 90;
    background-color: rgba(29, 34, 39, 0.5);
    position: absolute;
    border: 1px solid rgb(13, 20, 28);
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.infopanel {
    text-align: center;
    padding: 20px;
    font: 25px MyriadPro-Light;
    overflow-y: auto;
    color: #ffffff;
    opacity: 0.7;
}


.reroutinglabelwindow {
    overflow-y: auto;
    z-index: 400;
    /*background-color: rgba(51, 153, 204, 0.7);*/
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 15px;
    position: absolute;
    top: 35%;
    left: 25%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.reroutinglabelpanel {
    text-align: left;
    padding: 20px;
    font: 30px MyriadPro-Light;
    overflow-y: auto;
    color: #ffffff;
    opacity: 0.7;
}
.app-demoscreen {
    display: grid;
    width: 100vw;
    height: 100vh;
    text-align: center;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "lefttop center righttop" "leftbottom center rightbottom";
}

.kpi-demoscreen {
    display: grid;
    width: 100vw;
    height: 100vh;
    text-align: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "lefttop righttop" "leftbottom rightbottom";
}

.lefttop {
    grid-area: lefttop;
}

.righttop {
    grid-area: righttop;
}

.center {
    grid-area: center;
}

.leftbottom {
    grid-area: leftbottom;
}

.rightbottom {
    grid-area: rightbottom;
}

.app-demoscreen iframe {
    height: 100%;
    width: 100%;
}

.kpi-demoscreen iframe {
    height: 100%;
    width: 100%;
}
.canary {
    position: absolute;
    right: 12px;
    bottom: 12px;
    height: 10px;
    width: 10px;
    border-radius: 5px;
}

.canary.green {
    background-color: rgba(0, 255, 0, 0.76);
    box-shadow: 2px 4px 8px 0 rgba(109, 245, 63, 0.35), 0 6px 20px 0 rgba(178, 220, 11, 0.35);
}

.canary.red {
    background-color: rgba(255, 0, 0, 0.76);
    box-shadow: 2px 4px 8px 0 rgba(245, 109, 63, 0.35), 0 6px 20px 0 rgba(220, 178, 11, 0.35);
}

.canary.dead {
    display: none
}

.headline-news {
    position: absolute;
    left: 54px;
    bottom: 42px;
    right: 54px;
    height: 42px;
    font-family: 'Roboto';
}

.headline-news .header{
    padding: 4px 12px 4px 12px;
    background-color: #07365B;
    font-size: 24px;
    color: #FDFDFD;

    float:left;
}

.headline-news .band {
    padding-left: 16px;
    padding-right: 16px;
    background-color: #061923;
    border: 4px #07365B solid;
    font-size: 24px;
    color: #FDFDFD;

    overflow: hidden;
    box-sizing: border-box;
}

.headline-news .band .margins{
    overflow: hidden;
}

.headline-news .band .margins p{
    margin: 0px;
    white-space: nowrap;

    -webkit-transform: translate(1000px);

            transform: translate(1000px);
    display: inline-block;

    animation-name: slide;

    animation-timing-function: linear;
    animation-iteration-count: infinite;

    -webkit-animation-name: slide;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes slide {
    to {
      -webkit-transform: translate(-100%);
              transform: translate(-100%);
    }
}

@keyframes slide {
    to {
      -webkit-transform: translate(-100%);
              transform: translate(-100%);
    }
}
.logo {
    position: absolute;
    top: 0px;
    left: 50px;
}

.logo img {
    width: 91px;
    height: 97px;
}

section.situation-table {
    background-color: rgba(0, 21, 40, 0.66);
    width: 622px;
    padding: 26px 26px;
    position: absolute;
    right: 54px;
    top: 241px;
    min-height: 306px;
}

section.situation-table h5 {
    font-family: "Arial Rounded MT Bold", "Helvetica Rounded", Arial, sans-serif;
    font-size: 28px;
    line-height: 32px;
    color: #FDFDFD;
    text-align: center;
    padding-top: 0px;
    margin-top: 0px;
    margin-bottom: 30px;
}

section.situation-table table {
    table-layout: fixed;
    width: 622px;
    border: none;
    border-collapse: collapse;
    border-spacing: 0;
    margin: auto;
}

section.situation-table table thead tr th {
    font-family: 'Roboto';
    font-weight: 100;
    font-size: 20px;
    color: #BFBFBF;
    line-height: 24px;
    vertical-align: bottom;
    text-align: right;
    padding: 0px;
    border-bottom: 1px solid #8C8C8C;
}

section.situation-table table thead tr th:first-child {
    text-align: left;
    width: 338px;
}

section.situation-table table thead tr th:nth-child(2) {
    width: 118px;
}

section.situation-table table thead tr th:nth-child(3) {
    width: 118px;
}

section.situation-table table tbody tr td {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 24px;
    height: 38px;
    border-bottom: 1px solid #8C8C8C;
    text-align: right;
    padding: 0px;
}

section.situation-table table tbody tr td:first-child {
    text-align: left;
    color: #FDFDFD;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

section.situation-table table tbody tr td:nth-child(2) {
    color: #85AFD4;
}

section.situation-table table tbody tr td:nth-child(3) span {
    color: #FDFDFD;
    height: 32px;
    width: 88px;
    padding-left: 20px;
    padding-right: 20px;
}

section.situation-table table tbody tr td:nth-child(3) span.regulated {
    background-color: rgba(4, 111, 204, 0.33);
}

section.situation-table table tbody tr td:nth-child(3) span.warn {
    background-color: rgba(211, 105, 8, 0.5);
}

section.situation-table table tbody tr td:nth-child(3) span.alert {
    background-color: rgba(192, 80, 70, 0.5)
}

section.situation-table table tbody tr td:nth-child(3) span.critical {
    background-color: rgba(192, 80, 70, 1)
}

.show-off {
    -webkit-animation: show-off 0.75s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
            animation: show-off 0.75s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes show-off {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}

@keyframes show-off {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}
div.slide-show-indicator{
    width: 622px;
    position: absolute;
    right: 54px;
    top: 599px;
    padding: 0px 26px 26px 26px;
    background-color: rgba(0,21,40,0.66);
}

div.slide-show-indicator div.slider {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-left:auto;
    margin-right:auto;

    width: 36px;
}

div.slide-show-indicator div.slider span {
    border: 1px solid #FDFDFD;
    width:12px;
    height:12px;
    border-radius: 50%;
    display: inline-block;
}

div.slide-show-indicator div.slider span.selected {
    background-color:  #FDFDFD;
}
div.traffic-situation-kpi div.title{
    width: 105px;
    line-height: 30px;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid #8C8C8C;
    color: #BFBFBF;
    text-align: center;
}

div.traffic-situation-kpi div.value{
    width: 105px;
    line-height: 32px;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 600;
    color: #85AFD4;
    text-align: center;
}

div.traffic-situation-kpi div.title-large{
    width: 120px;
    line-height: 32px;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
    border-bottom: 1px solid #8C8C8C;
    color: #BFBFBF;
    text-align: center;
}

div.traffic-situation-kpi div.value-large{
    width: 120px;
    line-height: 38px;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 600;
    color: #85AFD4;
    text-align: center;
}
img.icon {
    -webkit-filter: invert(98%) sepia(1%) saturate(2517%) hue-rotate(285deg) brightness(125%) contrast(92%);
            filter: invert(98%) sepia(1%) saturate(2517%) hue-rotate(285deg) brightness(125%) contrast(92%);
    margin-left: auto;
    margin-right: auto;
    display:block;
}
section.traffic-situation{
    background-color: rgba(0,21,40,0.66);
    width: 622px;
    padding: 22px 26px 0px 26px;
    position: absolute;
    right: 54px;
    top: 54px;
}

section.traffic-situation h5{
    font-family: "Arial Rounded MT Bold", "Helvetica Rounded", Arial, sans-serif; 
    font-size: 28px;
    line-height: 32px;
    color: #FDFDFD;
    text-align: center;
    padding-top: 0px;
    margin-top: 0px;
    margin-bottom: 30px;
}
section.traffic-situation .container{
    display: flex;
    justify-content: center;
}

section.traffic-situation .container .big-icons{
    display: inline-flex;
    grid-gap: 20px;
    gap: 20px;
    margin-left: 26px;
}

section.traffic-situation .container .small-icons{
    display: inline-flex;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 11px;
    padding-left: 26px;
    margin-right: 26px;
}
.director .locationLabel{
    font: 400 18px 'Roboto';
    color: #F5F5F5;
    background:none;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
.indicator-kpi {
    width: 100%;
    height: 100%;
    text-align: center;
    flex-direction: column;
    position: relative;
}

.indicator-kpi > div {
    display: inline-block;
    color: #307ec7;
    text-align: center;
}

.indicator-kpi .icon {
    width: 86%;
    height: 86%;
    background: #003366;
    border-radius: 900px;
    text-align:center;
}

.indicator-kpi .icon img {
    width: 70%;
    height: 70%;
}

.indicator-kpi .title {
    width: 80%;
    height: 20%;
    top: 54%;
    left: 10%;
    font-size: 5.7vw;
    font-weight: 600;
    color: #3399cc;
    position:absolute;
}

.indicator-kpi .value {
    width: 80%;
    height: 30%;
    top: 60%;
    left: 10%;
    font-size: 10vw;
    color: white;
}

.icon .planned {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.icon .airborne {
    -webkit-transform: rotate(-30deg) translate(5%, 5%);
            transform: rotate(-30deg) translate(5%, 5%);
}

.icon .landed {
    -webkit-transform: rotate(30deg) translate(5%, 5%);
            transform: rotate(30deg) translate(5%, 5%);
}

.indicator {
    color: #6d6e70;
    padding: 5px;
    font-size: larger;
    max-width: 300px;
    position: relative;
}

.indicator > div:first-child {
    padding-left: 15px;
    text-align: left;
}

.indicator > div:nth-child(2) {
    display: flex;
}

.indicator .ind-value {
    display: flex;
    align-items: center;
}

.indicators-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row: auto;
    /*grid-column-gap: 20px;*/
    grid-row-gap: 20px;
    position: relative;
}

.indicators-block > div.indicator {
    max-width: 150px;
}

.indicator.line {
    max-width: 300px;
    text-align: left;
    padding-left: 19px;
}

.indicator.line > span {
    display: inline-block;
}

.indicator.line > span:first-child {
    min-width: 115px;
}

.indicator.line > span:nth-child(2) {
    color: white;
    font-size: larger;
}

.indicator.table {
    font-size: large;
}

.indicator.table > div:first-child {
    border-bottom: #fff 1px solid;
    padding: 5px;
}

.indicator.table > div:nth-child(2) {
    flex-direction: column;
    color: white;
    width: 100%;
}

.row-delays {
    float: left;
    border-bottom: #fff 1px solid;
    padding: 1.6vh;
}

.row-delays div.id {
    width: 15%;
    float: left;
}

.row-delays div.type {
    width: 55%;
    text-align: left;
    float: left;
    word-break: break-all;
}

.row-delays div.value {
    width: 24%;
    text-align: center;
    float: left;
    margin-left: 5%;
    border-radius: 8px;
    line-height: 1.3;
    -webkit-transition: background-color 600ms linear, color 600ms linear;
    transition: background-color 600ms linear, color 600ms linear;
}

.row-delays div.value.critical {
    background-color: red;
}

.row-delays div.value.alert {
    background-color: rgba(245, 102, 00, 1);
}

.row-delays div.value.warn {
    background-color: rgba(255, 255, 00, 1);
    color: black;
}

.row-delays div.value.regulated {
    background-color: white;
    color: black;
}

.display-kpis {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #0e171d;
    padding: 5.3% 6.3% 13.9% 6.3% ;
    grid-column-gap: 15%;
}

.display-logo {
    position: absolute;
    top: 0;
    right: 5%;
    z-index: 1000;
    height: 10vh;
}
.ticker {
    text-align: left;
    white-space: nowrap;
    display: inline-block;
    position: absolute;
    padding: 3vh 0;
    bottom: 0;
    left: 0;
    height: 10vh;
    width: 100%;
    overflow: hidden;
    color: white;
    font-size: 3.6vh;
}

.ruler {
    white-space: nowrap;
    width: auto;
}

.display-map {
    position: relative;
}

#map-fullscreen {
    width: 100vw;
    height: 100vh;
}


.top5-table {
    background-color: #0f3764;
    height: 100vh;
    font-family: BloggerSans;
}

.top5-table .title {
    padding-top: 3.5%;
    color: white;
    font-weight: 600;
    text-align: center;
    font-size: 5.8vw;
}

.top5-table .header {
    font-family: BloggerSans;
    font-weight: 100;
    line-height: 1;
}

.top5-table .header > div {
    display: inline-block;
    font-weight: bolder;
    font-size: small;
    font-size: 5.7vh;
    color: white;
    padding-bottom: .7vh;
}

.top5-table .indicator.table > div:first-child {
    border-bottom: white 2px solid;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1.6vh;
    padding-right: 1.6vh;
}

.top5-table .header > div:nth-child(1) {
    width: 50%;
}

.top5-table .header > div:nth-child(2) {
    width: 30%;
    text-align: right;
}

.top5-table .header > div:nth-child(3) {
    width: 20%;
    text-align: right;
}

.top5-table .indicator.table {
    padding: 3.5% 5.5%;
    width: 89%;
    max-width: none;
    font-size: 5.7vh;
    padding-top: 2.2%;
}

.row-delays {
    font-family: BloggerSans;
    position: relative;
    padding-bottom: .7vh;
    text-transform: uppercase;
}

.row-delays > div {
    display: inline-block;
}

.row-delays div.id {
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.row-delays div.delay {
    text-align: right;
    width: 30%;
}

.row-delays div.value {
    margin-left: 8%;
    width: 12%;
    float: none;
    border-radius: 90px;
    position: relative;
    top: -.5vh;
}

.row-delays div.value span {
    position: relative;
    top: .5vh;
}

.tracking-in-expand {
    -webkit-animation: tracking-in-expand 0.75s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
            animation: tracking-in-expand 0.75s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}

@keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}
.Dropzone {
    width:330px;
    height:150px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    -webkit-transition: border .24s ease-in-out;
    transition: border .24s ease-in-out;
}

.DropzoneReject {
    width:330px;
    height:150px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #ff1744;
    color: #ffffff;
    opacity: 0.75;
    outline: none;
    -webkit-transition: border .24s ease-in-out;
    transition: border .24s ease-in-out;
}

.DropzoneAccept {
    width:330px;
    height:150px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #00e676;
    color: #ffffff;
    opacity: 0.75;
    outline: none;
    -webkit-transition: border .24s ease-in-out;
    transition: border .24s ease-in-out;
}

.successMessage {
    font-size: 20px;
    color: #00e676;
    text-align: center;
}

.failureMessage {
    font-size: 20px;
    color: #ff1744;
    text-align: center;
}

.downloadLink {
    font-size: 18px;
    text-align: center;
    padding-top: 25px;
}

.App {
    min-height: 100vh;
    min-width: 100vw;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}


.headline-news {
    position: absolute;
    left: 54px;
    bottom: 42px;
    right: 54px;
    height: 42px;
    font-family: 'Roboto';
}

.headline-news .header{
    padding: 4px 12px 4px 12px;
    background-color: #07365B;
    font-size: 24px;
    color: #FDFDFD;

    float:left;
}

.headline-news .band {
    padding-left: 16px;
    padding-right: 16px;
    background-color: #061923;
    border: 4px #07365B solid;
    font-size: 24px;
    color: #FDFDFD;

    overflow: hidden;
    box-sizing: border-box;
}

.headline-news .band .margins{
    overflow: hidden;
}

.headline-news .band .margins p{
    margin: 0px;
    white-space: nowrap;

    transform: translate(1000px);
    display: inline-block;

    animation-name: slide;

    animation-timing-function: linear;
    animation-iteration-count: infinite;

    -webkit-animation-name: slide;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
}

@keyframes slide {
    to {
      transform: translate(-100%);
    }
}
.ticker {
    text-align: left;
    white-space: nowrap;
    display: inline-block;
    position: absolute;
    padding: 3vh 0;
    bottom: 0;
    left: 0;
    height: 10vh;
    width: 100%;
    overflow: hidden;
    color: white;
    font-size: 3.6vh;
}

.ruler {
    white-space: nowrap;
    width: auto;
}

section.situation-table {
    background-color: rgba(0, 21, 40, 0.66);
    width: 622px;
    padding: 26px 26px;
    position: absolute;
    right: 54px;
    top: 241px;
    min-height: 306px;
}

section.situation-table h5 {
    font-family: "Arial Rounded MT Bold", "Helvetica Rounded", Arial, sans-serif;
    font-size: 28px;
    line-height: 32px;
    color: #FDFDFD;
    text-align: center;
    padding-top: 0px;
    margin-top: 0px;
    margin-bottom: 30px;
}

section.situation-table table {
    table-layout: fixed;
    width: 622px;
    border: none;
    border-collapse: collapse;
    border-spacing: 0;
    margin: auto;
}

section.situation-table table thead tr th {
    font-family: 'Roboto';
    font-weight: 100;
    font-size: 20px;
    color: #BFBFBF;
    line-height: 24px;
    vertical-align: bottom;
    text-align: right;
    padding: 0px;
    border-bottom: 1px solid #8C8C8C;
}

section.situation-table table thead tr th:first-child {
    text-align: left;
    width: 338px;
}

section.situation-table table thead tr th:nth-child(2) {
    width: 118px;
}

section.situation-table table thead tr th:nth-child(3) {
    width: 118px;
}

section.situation-table table tbody tr td {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 24px;
    height: 38px;
    border-bottom: 1px solid #8C8C8C;
    text-align: right;
    padding: 0px;
}

section.situation-table table tbody tr td:first-child {
    text-align: left;
    color: #FDFDFD;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

section.situation-table table tbody tr td:nth-child(2) {
    color: #85AFD4;
}

section.situation-table table tbody tr td:nth-child(3) span {
    color: #FDFDFD;
    height: 32px;
    width: 88px;
    padding-left: 20px;
    padding-right: 20px;
}

section.situation-table table tbody tr td:nth-child(3) span.regulated {
    background-color: rgba(4, 111, 204, 0.33);
}

section.situation-table table tbody tr td:nth-child(3) span.warn {
    background-color: rgba(211, 105, 8, 0.5);
}

section.situation-table table tbody tr td:nth-child(3) span.alert {
    background-color: rgba(192, 80, 70, 0.5)
}

section.situation-table table tbody tr td:nth-child(3) span.critical {
    background-color: rgba(192, 80, 70, 1)
}

.show-off {
    animation: show-off 0.75s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@keyframes show-off {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}